import { AxiosResponse } from 'axios';
import { VC_SCHEMA, eventDetails } from '@/constants';
import { IssueCredentialPayload, EventAttendanceCredential } from '@/types';
import { serverApi } from './axiosHelper';

const title = `Here's your credential for attending ${eventDetails.event}`;
const name = `${eventDetails.event} Attendance Credential`;
const summary = `Proof of attendance at ${eventDetails.event}`;
const disclaimer = `This credential has been issued on the Verida testnet`;

export const defineCredential = (): EventAttendanceCredential => {
  const date = new Date();
  return {
    event: eventDetails.event,
    date: eventDetails.date,
    location: eventDetails.location,
    disclaimer,
    issueTimestamp: date.toLocaleString(),
  };
};

export const claimCredential = async (did: string): Promise<void> => {
  const credentialData: IssueCredentialPayload = {
    did,
    data: {
      schema: VC_SCHEMA,
      title,
      name,
      summary,
      ...defineCredential(),
    },
  };
  await serverApi.post<void, AxiosResponse<void, any>, IssueCredentialPayload>(
    '/credential/issue',
    credentialData
  );
};
