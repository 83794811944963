class Verida {
  private context: any;
  public did = '';
  public connected?: boolean;
  public errors?: Error;

  public async handleConnected(context: any): Promise<void> {
    this.context = context;
    this.connected = true;
    this.did = await context.getAccount().did();
  }

  public handleLogout() {
    this.context = null;
    this.connected = false;
  }
}

export const veridaHelper = new Verida();
