<template>
  <home />
</template>
<script>
import Home from '@/views/Home.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Home },
  name: 'App',
  props: {},
});
</script>
