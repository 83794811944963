
import { defineComponent } from 'vue';
import { veridaHelper } from '@/api';
import { CONTEXT_NAME, LOGO_URL } from '@/constants';

export default defineComponent({
  emits: ['setAppStatus'],
  data() {
    return {
      isOpened: false,
      contextName: CONTEXT_NAME,
      logo: LOGO_URL,
      openUrl: window.location.href,
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpened = !this.isOpened;
    },
    async onConnected(context: any) {
      await veridaHelper.handleConnected(context);
      this.$emit('setAppStatus');
    },
    async onLogout() {
      veridaHelper.handleLogout();
      this.$emit('setAppStatus');
    },
  },
});
