const {
  VUE_APP_CONTEXT_NAME,
  VUE_APP_LOGO_URL,
  VUE_APP_API_AUTH_TOKEN,
  VUE_APP_API_BASE_URL,
  VUE_APP_EVENT_NAME,
  VUE_APP_EVENT_LOCATION,
  VUE_APP_EVENT_DATE,
}: any = process.env;

export const CONTEXT_NAME = VUE_APP_CONTEXT_NAME;
export const LOGO_URL = VUE_APP_LOGO_URL;
export const API_AUTH_TOKEN = VUE_APP_API_AUTH_TOKEN;
export const VC_SCHEMA = `${window.location.origin}/schemas/eventAttendance/latest/schema.json`;
export const API_BASE_URL = VUE_APP_API_BASE_URL;

export const eventDetails = {
  event: VUE_APP_EVENT_NAME as string,
  date: VUE_APP_EVENT_DATE as string,
  location: VUE_APP_EVENT_LOCATION as string,
};
